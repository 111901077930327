
import { defineComponent, onBeforeUnmount, onMounted, reactive, watch } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { useBottomBar } from '@/compositions/bottomBar'

export default defineComponent({
  components: {
    TmFormLine,
    InfoCards,
    InfoCardRow,
    DetailsHero,
    InfoCard,
    PageContent,
    TmButton,
  },
  setup() {
    const { provideData, getDiscardFunc } = useBottomBar()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Connected inboxes', url: { name: 'base.tickets.settings.inboxes' } },
      { label: 'johndoe@gmail.com' },
    ]
    const { openDeleteInboxModal, openSendersModal } = useTicketsOpenModals()

    const infos = [
      { label: 'Textmagic inbox' },
    ]

    const state = reactive({
      senderName: 'Textmagic Support',
      addAgent: false,
      assignTo: assigneeOptions[2],
    })

    const defaultState = Object.assign({}, state)

    watch(() => state, (newValue) => {
      JSON.stringify(newValue) !== JSON.stringify(defaultState) ? provideData(1) : getDiscardFunc.value()
    }, { deep: true })

    const restoreEnabled = () => {
      Object.assign(state, defaultState)
    }

    onMounted(() => {
      provideData(0, restoreEnabled)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      assigneeOptions,
      state,
      breadcrumbs,
      infos,
      openDeleteInboxModal,
      openSendersModal,
    }
  },
})
